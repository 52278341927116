@import "styles/_variables.scss";

.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #FCFBF8;
    border-radius: 8px;
    border: 1px solid #DFDEDF;
    width: 100%;
    height: 100%;
    transition: box-shadow 0.2s linear;

    &:hover {
        transition: box-shadow 0.2s linear;
        box-shadow: 0px 4px 16px rgba(99, 86, 60, .25);

        .card__link__arrow {
            transition: transform .1s linear;
            transform: translateX(4px);
        }
    }

    &__content {
        padding: 20px;
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__title {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        color: $mainText;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        
        @media(max-width: $tabletPoint) {
            -webkit-line-clamp: 4;
        }
        @media(max-width: $mobilePoint) {
            -webkit-line-clamp: 3;
        }
    }

    &__text {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: $mainText;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        
        *{
            margin: 0;
        }

        @media(max-width: $tabletPoint) {
            -webkit-line-clamp: 6;
        }
        @media(max-width: $mobilePoint) {
            -webkit-line-clamp: 3;
        }
    }

    &__image {
        position: relative;

        img {
            object-fit: cover;
            border-radius: 7px 7px 0px 0px;
        }
    }

    &__link {
        &__arrow {
            width: 24px;
            height: 24px;
            transition: transform .1s linear;
        }

        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: $greenBackground;
        display: flex;
        align-items: center;
    }
}
