@import "styles/_variables.scss";

.section {
    padding-bottom: 0;
}

.title{
    text-align: center;
    margin-bottom: 40px;
}

.button{
    max-width: 176px;
    margin: 0 auto;
}

.cards{
    &__items{
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        margin-bottom: 20px;
        @media(max-width: $mobilePoint) {
            flex-direction: column;
            column-gap: 0;
        }
    }
    &__item{
        width: 384px;
        height: 440px;
        margin-bottom: 20px;
        @media(max-width: $tabletPoint) {
            width: 228px;
            height: 556px;
        }

        @media(max-width: $mobilePoint) {
            width: 100%;
            height: 472px;
        }
    }
}
