@import "styles/_variables.scss";

.button {
  border: 1px solid $greenBorderColor;
  border-radius: 4px;
  color: $greenBackground;
  cursor: pointer;
  font-family: $fontFamily;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  padding: 11px 16px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
  transition: all 0.2s linear;

  svg {
    transition: all 0.1s linear;
  }

  span {
    display: inline-block;
    margin-left: 6px;
  }

  &:hover {
    background-color: $greenBackground;
    color: $white;

    svg {
      transform: translateX(4px);

      path {
        fill: $white;
      }
    }
  }
}
