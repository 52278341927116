// Fonts
$fontFamily: 'Inter', sans-serif;

// Colors
$white: #FFFFFF;
$black: #111111;
$error: #D73F2D;

$greenBackground: #024D40;
$whiteBackground: #FCFBF8;
$greenBorderColor: #7DA29A;
$paleGreenBackground: #ECF4EF;
// Sizes
$headerHeight: 80px; // Without banner "Save Ukraine"
//$headerHeight: 135px; // With banner "Save Ukraine"

// Containers
$largeDesktopContainer: 1340px;
$middleDesktopContainer: 1200px;
$smallDesktopContainer: 732px;
$middleTabletContainer: 724px;
$smallTabletContainer: 600px;

// Adaptive points
$tabletPoint: 1200px;
$mobilePoint: 768px;
$smallMobilePoint: 555px;

// Footer
$footerBackground: #024D40;

// Header
$headerBlackBackground: #131e25;

// Uncategorized
$black: #111111;
$mainBlack: #1b1b1b;
$blogMainText: #1D1D1F;
$givingliMainText: #1D1D1F;
$blackShadow: #333;
$borderGrown: #353535;
$lightDarkBg: #242424;
$formBackground: rgba(30, 30, 32, 0.44);
$mainText: #1D1D1F;
$hrColor: #303030;
$strokeColor: #2f2f2f;
$navigationLangColor: rgba(83, 85, 111, 0.7);
$headerBorderList: rgba(213, 213, 213, 0.3);
$blogSecondText: #70707C;
$givingliSecondText: #70707c;
$secondaryText: #70707C;
$grayColor: #99A0AA;
$navigationLinkColor: #b2b2b2;
$menuButtonColor: rgba(255, 255, 255, 0.6);
$givingliBorderColor: #CCCCCC;
$greyBackground: #F5F3F0;
$faqLinesColor: #E3E3E3;
$faqGreyHoverColor: #F5F3F0;

$blueListPoint: #2E2ECA;
$purpleListPoint: #3434E2;
$purpleListPointBg: #F1F2FF;
$navigationLinkColorHover: #2a6496;

$navigationBorderColor: #e6e7f1;
$subscribeBgColor: #EBEBEB;
$bloghrColor: #DFDEDF;
$errorBg: #FAF1F1;
$successSubscribeBackground: #F1FAF7;
$inputsText: #FFFFFF;
$successBackground: #F0FAF2;
$failureBackground: #FFF2EE;
$defaultImageBackground: #F7F4F0;
$lightgreyBorder: #E3EAE6;
$technologyBackground: #F5F3F0;
$greyBorder: #D9D9D9;
$lolyptoPreviewBg: #FFC050;
$lolyptoResultsBg: #161719;
$blogTableBg: #E9F4EE;
$fintechHighlight: #DEEEE6;
$fintechHighlightDark: #9FDBBD;
$placeholderColor: #97979D;
$greenDarkModeHighlight: #008C74;

$rateStarColor:#FFDF48;
$customServiceSecurityBackground:#E3F5EC;
$customServicePaymentsBackground:#FFE3DF;
$customServiceSolutionBackground:#E3EEFF;
$customServiceP2PBackground:#FFEFE3;
$customServiceDesignBackground:#F5EFFF;

$customServiceSecurityTextColor:#024D40;
$customServicePaymentsTextColor:#D73F2D;
$customServiceSolutionTextColor:#003B94;
$customServiceP2PTextColor:#F68704;
$customServiceDesignTextColor:#6E2DD7;

$borderColorFAQ:#DFDBD0;
$shadowArticleColor: #1D1D1F1F;
$blogPageButtonBackground:#F0EFEB;
$readingTimeBackground:#F0EFEB;
$readingTimeLine: #D9D9D9;
$readingTimeBorder:#DFDEDF;


$verticalLineColor:rgba(217, 217, 217, 1);
$authorsBorderColor:rgba(2, 77, 64, 1);
$authorBackgroundColor:rgba(252, 251, 248, 1);
$authorBorderColor:rgba(252, 251, 248, 1);
$authorSecondaryBackground:rgba(245, 243, 240, 1);

